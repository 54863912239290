<template>
  <svg viewBox="0 0 40 40">
    <path
      d="M20.31 24.2539C19.2816 24.2539 18.6816 25.2322 18.6816 26.5422C18.6816 27.8606 19.3 28.7922 20.32 28.7922C21.3483 28.7922 21.9383 27.8122 21.9383 26.5022C21.9383 25.2922 21.3583 24.2539 20.31 24.2539V24.2539Z"
    />
    <path
      d="M23.3327 3.33398H9.99935C9.11529 3.33398 8.26745 3.68517 7.64233 4.31029C7.0172 4.93542 6.66602 5.78326 6.66602 6.66732V33.334C6.66602 34.218 7.0172 35.0659 7.64233 35.691C8.26745 36.3161 9.11529 36.6673 9.99935 36.6673H29.9993C30.8834 36.6673 31.7312 36.3161 32.3564 35.691C32.9815 35.0659 33.3327 34.218 33.3327 33.334V13.334L23.3327 3.33398ZM15.2577 29.0907C14.5477 29.6807 13.4693 29.959 12.151 29.959C11.3593 29.959 10.801 29.909 10.421 29.859V23.2407C11.1026 23.1425 11.7907 23.0963 12.4793 23.1023C13.7593 23.1023 14.5893 23.3323 15.2377 23.8223C15.9377 24.3423 16.3777 25.1723 16.3777 26.359C16.3777 27.6507 15.9077 28.5407 15.2577 29.0907V29.0907ZM20.241 30.0007C18.241 30.0007 17.0727 28.4907 17.0727 26.5707C17.0727 24.5523 18.361 23.044 20.351 23.044C22.4193 23.044 23.5493 24.5923 23.5493 26.4523C23.5477 28.6607 22.2077 30.0007 20.241 30.0007V30.0007ZM27.9993 28.7307C28.4577 28.7307 28.9677 28.629 29.2693 28.5107L29.4993 29.699C29.2193 29.839 28.5893 29.989 27.771 29.989C25.4427 29.989 24.2427 28.5407 24.2427 26.6207C24.2427 24.3223 25.881 23.044 27.921 23.044C28.711 23.044 29.3093 23.204 29.5793 23.344L29.2693 24.554C28.8636 24.3863 28.4284 24.3013 27.9893 24.304C26.7793 24.304 25.8393 25.034 25.8393 26.534C25.8393 27.8823 26.6393 28.7307 27.9993 28.7307V28.7307ZM23.3327 15.0007H21.666V6.66732L29.9993 15.0007H23.3327Z"
    />
    <path
      d="M12.6395 24.2715C12.3012 24.2715 12.0812 24.3015 11.9512 24.3315V28.7398C12.0812 28.7698 12.2912 28.7698 12.4795 28.7698C13.8595 28.7798 14.7578 28.0215 14.7578 26.4115C14.7678 25.0115 13.9495 24.2715 12.6395 24.2715V24.2715Z"
    />
  </svg>
</template>

<script>
export default {
  name: "FileDocIcon",
};
</script>
